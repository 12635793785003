.App {
  text-align: center;
}

body {
  padding: 0;
  margin: 0;
}

h2 {
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: 50;
  color: black;
  padding-left: 20px;
}
