body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-container {
  margin: 20px;
  padding: 20px;
  display: flex;
}

.project-container {
  margin: 20px;
}

h2 {
  margin: 0px;
  padding: 0px;
}

.questionnaire {
  outline: 1px solid red;
}
/* 
.category {
  display: none;
} */
