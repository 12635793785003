#show {
  display: inherit;
}

#hide {
  display: none;
}
/* 
.project {
  display: flex;
}

.proj-detail {
  border: 1px solid black;
  width: 50%;
  padding: 20px;
}

textarea {
  width: 100%;
  resize: none;
}

.edit-btn {
  height: 100%;
  float: right;
}
.input {
  flex: 2;
  margin-right: 30%;
  width: 50%;
}

.edit {
  display: flex;
  margin-bottom: 10px;
}

.proj-spec {
  border: 1px solid black;
  padding: 30px;
  width: 50%;
}

.approach {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.approach-output {
  height: auto;
}

input#QUALITATIVE {
  float: left;
}
label#QUALITATIVE {
  float: left;
}

input#QUANTITATIVE {
  float: left;
}

label#QUANTITATIVE {
  float: left;
} */
